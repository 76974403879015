import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
// import perf from "../../Components/Images/perf.jpg"; // Importing images directly
import perf from "../../Components/Images/perf.png"; // Importing images directly
import bottlings from "../../Components/Images/bottlings.jpg"; // Importing images directly
import bottlings2 from "../../Components/Images/bottlings2.jpg"; // Importing images directly
import bottlings3 from "../../Components/Images/bottlings3.jpg"; // Importing images directly
import bottlings4 from "../../Components/Images/bottlings4.jpg"; // Importing images directly
import bottlings5 from "../../Components/Images/bottlings5.jpg"; // Importing images directly
import bottlings6 from "../../Components/Images/bottlings6.jpg"; // Importing images directly
import bottlings7 from "../../Components/Images/bottlings7.jpg"; // Importing images directly
import bottlings8 from "../../Components/Images/bottlings8.jpg"; // Importing images directly
import bottlings9 from "../../Components/Images/bottlings9.jpg"; // Importing images directly
import bottlings10 from "../../Components/Images/bottlings10.jpg"; // Importing images directly
import bottlings11 from "../../Components/Images/bottlings11.jpg"; // Importing images directly
import bottlings12 from "../../Components/Images/bottlings12.jpg"; // Importing images directly
import bottlings13 from "../../Components/Images/bottlings13.jpg"; // Importing images directly
import bottlings14 from "../../Components/Images/bottlings14.jpg"; // Importing images directly
import bottlings15 from "../../Components/Images/bottlings15.jpg"; // Importing images directly
import bottlings16 from "../../Components/Images/bottlings16.jpg"; // Importing images directly
import "./Products.css";
const ProductData = [
  {
    id: 1,
    name: "Triple Magnesium Complex",
    brand: "400 mg, 100 Capsules",
    image: bottlings, // Use imported image
  },
  {
    id: 2,
    name: "Zinc & C with Elderberry & Echinacea",
    brand: "60 Lozenges",
    image: bottlings2, // Use imported image
  },
  {
    id: 3,
    name: "L-Carnitine",
    brand: "500 mg, 100 Tablets",
    image: bottlings3, // Placeholder, replace with actual image
  },
  {
    id: 4,
    name: "Tongkat Ali",
    brand: "400 mg, 120 Capsules",
    image: bottlings4, // Placeholder, replace with actual image
  },
  {
    id: 5,
    name: "Maca",
    brand: "60 Capsules",
    image: bottlings5, // Placeholder, replace with actual image
  },
  {
    id: 6,
    name: "Water Pills",
    brand: "120 Tablets",
    image: bottlings6, // Placeholder, replace with actual image
  },
  {
    id: 7,
    name: "Biotin Gummies",
    brand: "60 gummies",
    image: bottlings7, // Placeholder, replace with actual image
  },
  {
    id: 8,
    name: "Pure Blood Sugar",
    brand: "60 Veggie Capsules",
    image: bottlings8, // Placeholder, replace with actual image
  },
  {
    id: 9,
    name: "Prostate Essentials",
    brand: "90 Veggie Capsules",
    image: bottlings9, // Placeholder, replace with actual image
  },
  {
    id: 10,
    name: "Milk Thistle Liquid Extract",
    brand: "1 fl oz (29.6 ml)",
    image: bottlings10, // Placeholder, replace with actual image
  },
  {
    id: 11,
    name: "Chlorophyllin & Mint",
    brand: "500 Chewable Tablets",
    image: bottlings11, // Placeholder, replace with actual image
  },
  {
    id: 12,
    name: "Prebiotic Gummies",
    brand: "60 gummies",
    image: bottlings12, // Placeholder, replace with actual image
  },
  {
    id: 13,
    name: "Urinary & Bladder Support",
    brand: "500 mg , 30 Caps",
    image: bottlings13, // Placeholder, replace with actual image
  },
  {
    id: 14,
    name: "Thyroid Essentials",
    brand: "90 Capsules",
    image: bottlings14, // Placeholder, replace with actual image
  },
  {
    id: 15,
    name: "Uric Acid Cleanse",
    brand: "60 Capsules",
    image: bottlings15, // Placeholder, replace with actual image
  },
  {
    id: 16,
    name: "Inositol",
    brand: "100 Capsules",
    image: bottlings16, // Placeholder, replace with actual image
  },
  // Add more products as needed
];

function ProductList() {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Container fluid className="product-page">
      <NavBar />

      <Row
        className="justify-content-center mt-5 mb-4"
        style={{ paddingTop: "15px" }}
      >
        <Col xs="auto">
          <h1 className="text-center">All Products</h1>
        </Col>
      </Row>

      <Row className="justify-content-center mb-4">
        <Col xs={12} sm={8} md={6} lg={7}>
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search..."
              className="me-2"
              onChange={handleSearch}
            />
            <Button variant="primary" style={{ marginLeft: "15px" }}>
              Search
            </Button>
          </Form>
        </Col>
      </Row>

      {/* Start rendering products */}
      {ProductData.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
        .reduce((rows, product, index) => {
          // Every 4 products, start a new row
          if (index % 4 === 0) rows.push([]);

          rows[rows.length - 1].push(product);
          return rows;
        }, [])
        .map((productRow, rowIndex) => (
          <Row className="g-4 justify-content-center" key={rowIndex} style={{marginBottom: 70}}>
            <Col xs={2} className="hide-on-small-screens"></Col> {/* Empty first 2 columns */}
            {productRow.map((product, colIndex) => (
              <Col
                xs={12}
                sm={6}
                md={2}  
                lg={2}
                key={colIndex}
                className="d-flex justify-content-center"
              >
                <Card className="product-card">
              <div className="product-image-container">
                  <Card.Img
                    variant="top"
                    src={product.image}
                    alt={product.name}
                    className="product-image"
                  />
                  </div>
                  <Card.Body className="text-center">
                    <Card.Title className="product-title-hover product-title text-center">
                      {product.name}
                    </Card.Title>
                    <Card.Text className="product-brand">
                      {product.brand}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            <Col xs={2} className="hide-on-small-screens"></Col>
          </Row>
        ))}

      <Footer />
    </Container>
  );
}

export default ProductList;
