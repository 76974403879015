import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DouvitLogo from "../Images/DouvitLogo.svg";
import DuoVitLogo2 from "../Images/DuoVitLogo2.svg";

function NavBar() {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => setIsExpanded(!isExpanded);
  const handleClose = () => setIsExpanded(false);

  return (
    <div className="App">
      <Navbar
        expand="lg"
        expanded={isExpanded}
        style={{
          background: "#B22222",
          // background: "#8B1A1A",
          height: "100px",
          fontSize: 20,
          zIndex: 100000,
        }}
        variant="dark"
      >
        <Container className="d-flex align-items-center justify-content-between">
          {/* Logo and burger icon in the same flex container */}
          <div
            className="d-flex align-items-center w-100 justify-content-between"
            style={{ zIndex: 1001 }} // Ensure logo is above the overlay
          >
            {/* Logo on the left */}
            <Navbar.Brand href="/" className="d-flex align-items-center">
              <img
                src={DuoVitLogo2}
                alt="Logo"
                style={{ width: "100%" }}
                className="d-inline-block align-top"
              />
            </Navbar.Brand>

            {/* Navbar toggler (burger icon for small screens) */}
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="d-lg-none" // Hide toggler on large screens
              onClick={handleToggle}
            />
          </div>

          {/* Collapsible navigation links */}
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={`justify-content-end ${isExpanded ? "show" : ""}`}
            style={{
              zIndex: 1000, // Ensure it's above the background container
              position: "relative",
              background: "#B22222",
              // background: "#8B1A1A",
              transform: isExpanded ? "translate(25%, 40%)" : "",
              fontSize: isExpanded? "2.5rem" : ""
              
            }}
          >
            <Nav onClick={handleClose} className="ml-auto">
              <Nav.Link href="/" className="px-3 text-white">
                Home
              </Nav.Link>
              <Nav.Link href="/products" className="px-3 text-white">
                Products
              </Nav.Link>
              <Nav.Link href="/partners" className="px-3 text-white">
                Partners
              </Nav.Link>
              <Nav.Link href="/about" className="px-3 text-white">
                About
              </Nav.Link>
              <Nav.Link href="/contact" className="px-3 text-white">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>

        {/* Background container for the expanded menu */}
        {isExpanded && (
          <div
            style={{
              position: "fixed",
              top: 0, // Start from the top of the viewport
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "#B22222", // Change to the desired color
              // backgroundColor: "#8B1A1A", // Change to the desired color
              zIndex: 999,
            }}
            onClick={handleClose} // Close the menu when background is clicked
          />
        )}
      </Navbar>
    </div>
  );
}

export default NavBar;
