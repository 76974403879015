import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaPhone, FaFax, FaEnvelope, FaFacebook, FaInstagram } from "react-icons/fa";

function Footer() {
  return (
    <footer
      // style={{ backgroundColor: "#B22222", color: "white", padding: "40px 0" }}
      style={{ backgroundColor: "#28282B", color: "white", padding: "40px 0" }}
    >
      <Container>
        <Row className="text-center">
          {/* Company Info */}
          <Col md={3}>
          <div style={{ paddingBottom: "1rem" }}>
            <h5>Duo Vit</h5>
            <p>
            One trusted partner at a time.
            </p>
            </div>
          </Col>

          {/* Address Info */}
          <Col md={3}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", paddingBottom: "2.3rem" }}>
            <h5>Address Info</h5>
            {/* Each row now has a fixed-width icon and aligned text */}
            <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
              <FaPhone style={{ width: "20px", minWidth: "20px" }} />
              <div style={{ marginLeft: "10px" }}>+962790300178</div>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
              <FaFax style={{ width: "20px", minWidth: "20px" }} />
              <div style={{ marginLeft: "10px" }}>+962790300178</div>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginTop: "10px", marginLeft: "25px" }}>
              <FaEnvelope style={{ width: "20px", minWidth: "20px" }} />
              <div style={{ marginLeft: "10px" }}>official@duovit.info</div>
            </div>
          </div>
        </Col>
        
        
        

          {/* Quick Links */}
          <Col md={3}>
            <h5>Quick Links</h5>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <a href="/" style={{ color: "inherit", textDecoration: "none" }}><li>Home</li></a>
              <a href="/products" style={{ color: "inherit", textDecoration: "none" }}><li>Products</li></a>
              <a href="/partners" style={{ color: "inherit", textDecoration: "none" }}><li>Partners</li></a>
              <a href="/about" style={{ color: "inherit", textDecoration: "none" }}><li>About Us</li></a>
              <a href="/contact" style={{ color: "inherit", textDecoration: "none" }}><li>Contact Us</li></a>
            </ul>
          </Col>

          {/* Social Media */}
          <Col md={2}>
            <h5>Contact Us</h5>
            <div>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ color: "inherit", textDecoration: "none" }}>
                <FaFacebook style={{ marginRight: "10px" }} />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={{ color: "inherit", textDecoration: "none" }}>
                <FaInstagram />
              </a>
            </div>
          </Col>
        </Row>

        {/* Footer Bottom */}
        <Row className="mt-3">
        <hr />
          <Col className="text-center">
            <p>&copy; 2024 DV. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
