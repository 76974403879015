import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import randomlo from "../../Components/Images/randomlo.jpg"; // Importing images directly
import randomlo2 from "../../Components/Images/randomlo2.jpg"; // Importing images directly
import randomlo3 from "../../Components/Images/randomlo3.jpg"; // Importing images directly
import randomlo4 from "../../Components/Images/randomlo4.jpg"; // Importing images directly
import swanson from "../../Components/Images/swanson.png"; // Importing images directly
import "./Partners.css";
const ProductData = [
  {
    id: 1,
    name: "Swanson Health Products",
    image: swanson, // Use imported image
  }
 
];

function Partners() {
  return (
    <Container fluid className="product-page">
      <NavBar />

      <Row
        className="justify-content-center mt-5"
        style={{ paddingTop: "30px" }}
      >
        <Col xs="auto">
          <h1 className="text-center">Partners</h1>
        </Col>
      </Row>

      {/* Start rendering products */}
      {ProductData.reduce((rows, product, index) => {
          // Every 4 products, start a new row
          if (index % 4 === 0) rows.push([]);

          rows[rows.length - 1].push(product);
          return rows;
        }, [])
        .map((productRow, rowIndex) => (
          <Row className="g-4 justify-content-center pt-5  " key={rowIndex}>
            <Col xs={2} className="hide-on-small-screens"></Col> {/* Empty first 2 columns */}
            {productRow.map((product, colIndex) => (
              <Col
                xs={12}
                sm={6}
                md={2}
                lg={2}
                key={colIndex}
                className="d-flex justify-content-center"
              >
                <a href="https://www.swansonvitamins.com/" target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>
                  <Card className="product-card">
                    <Card.Img
                      variant="top"
                      src={product.image}
                      alt={product.name}
                      className="product-image"
                    />
                    <Card.Body className="text-center">
                      <Card.Title className="product-title">
                        {product.name}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            ))}
            <Col xs={2} className="hide-on-small-screens"></Col> {/* Empty last 2 columns */}
          </Row>
        ))}
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </Container>
  );
}

export default Partners;
