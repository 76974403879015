import React from "react";
import { Carousel, Container, Row, Col, Button } from "react-bootstrap";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import PillsImg from "../../Components/Images/PillsImg.jpg";
import shipping from "../../Components/Images/shipping.jpg";
import PillsImg2 from "../../Components/Images/PillsImg2.jpg";
import PillsImg3 from "../../Components/Images/PillsImg3.jpg";
import PillsImg23 from "../../Components/Images/PillsImg23.jpg";
import truck1 from "../../Components/Images/truck1.jpg";
import meeting1 from "../../Components/Images/meeting1.jpg";
import forkelift from "../../Components/Images/forkelift.jpg";
import reliable from "../../Components/Images/reliable.jpg";
import loadingup2 from "../../Components/Images/loadingup2.jpg";
import commitm from "../../Components/Images/commitm.jpg";
import "./Landing.css";
import { CiPillsBottle1 } from "react-icons/ci";
import { ImFileMusic, ImGift } from "react-icons/im";
import { FaBoxOpen } from "react-icons/fa";
import { BsTruck } from "react-icons/bs";
import { IoChatbubblesOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FaTruck } from "react-icons/fa6";
import { IoChatbubbles } from "react-icons/io5";
function Landing() {
  return (
    <div className="App">
      <NavBar />
      <Container fluid className="p-0">
      <Carousel>
      <Carousel.Item interval={3000} className="carousel-item-landing">
        <img
          className="d-block w-100 carousel-img"
          src={shipping}
          alt="First slide"
          style={{ height: "90vh", objectFit: "cover", opacity: 0.7 }}
        />
        <Carousel.Caption
          style={{
            position: "absolute",
            top: "47%",
            left: "52%",
            transform: "translate(-10%, -30%)",
            textAlign: "left",
            maxWidth: "40%",
            padding: "0px 0px 0px 0px !important",
          }}
          className="carousel-caption-landing"
        >
          <h1
            style={{
              fontWeight: "800",
              fontSize: "65px",
              textTransform: 'uppercase',
              color: "black",
            }}
            className="carousel-caption-heading"
          >
          The Company YOU Can Trust! 
          </h1>
          <p className="carousel-caption-text" style={{ color: "black", fontWeight: 600, fontSize: "1.4rem" }}>
          In an era characterized by fast food and fleeting trends, the founders of Duovit chose a different route — one based on health, honesty and communication. It started with a simple idea that everyone should have access to real, good-for-you food and personal care products.
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100 carousel-img"
          src={truck1}
          alt="Second slide"
          style={{ height: "90vh", objectFit: "cover", opacity: 0.7 }}
        />
        <Carousel.Caption
          style={{
            position: "absolute",
            top: "47%",
            left: "52%",
            transform: "translate(-10%, -30%)",
            textAlign: "left",
            maxWidth: "40%",
          }}
          className="carousel-caption-landing"
        >
          <h1
            style={{
              fontWeight: "800",
              fontSize: "65px",
              textTransform: 'uppercase',
              color:"black"
            }}
            className="carousel-caption-heading"
          >
          Reaching out the world
          </h1>
          <p className="carousel-caption-text" style={{ color: "black", fontWeight: 600, fontSize: "1.4rem" }}>
          Duovit's team are experts in every kind of food and wellness brand distribution. With a variety of specialized resources. We are fully prepared to provide for even the most challenging transportation requirements; always making certain your goods get where they need in one piece.
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100 carousel-img"
          src={reliable}
          alt="Third slide"
          style={{ height: "90vh", objectFit: "cover", opacity: 0.7 }}
        />
        <Carousel.Caption
          style={{
            position: "absolute",
            top: "47%",
            left: "52%",
            transform: "translate(-10%, -30%)",
            textAlign: "left",
            maxWidth: "40%",
          }}
          className="carousel-caption-landing"
        >
          <h1
            style={{
              fontWeight: "800",
              fontSize: "65px",
              textTransform: 'uppercase',
              color:"black"
            }}
            className="carousel-caption-heading"
          >
          Reliable
          </h1>
          <p className="carousel-caption-text" style={{ color: "black", fontWeight: 600, fontSize: "1.4rem" }}>
          This drives our core purpose, leading us to supply a superior distribution service in Melbourne and place as an employer of choice at Duovit. We are committed to delivering your food and wellness products wherever you need them most.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
      </Container>
      {/* Our Commitment Section */}
      <section className="commitment-section">
        <Container fluid className="p-0">
          <Row className="g-0">
            {/* Text Column */}
            <Col
              md={8}
              className="commitment-text p-0 d-flex"
              style={{
                backgroundColor: "#B22222",
                // backgroundColor: "#8B1A1A",
                padding: "50px",
                color: "#fff",
                justifyContent: "flex-start", // Aligns items to the left initially
              }}
            >
              <div
                style={{
                  marginLeft: "33.5%", // Push the content to the right, leaving 5/8 empty
                  marginRight: "12.5%", // Push the content to the right, leaving 5/8 empty
                  paddingTop: "40px",
                  justifyContent: "flex-end",
                }}
                className="commitment-text-div"
              >
                {/* Heading */}
                <h2
                  style={{
                    fontWeight: "bold",
                    fontSize: "42px",
                    color: "#fff",
                    marginBottom: "20px",
                    textAlign: "left", // Left align the text
                  }}
                >
                  OUR COMMITMENT
                </h2>
                {/* Bold first paragraph */}
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: "17px",
                    marginBottom: "0px", // Add space after the first paragraph
                    padding: "0 0 27px 0",
                    lineHeight: "25px",
                  }}
                >
                At Duovit, we keep on reminding us about high quality and perfection in any product that has our name. Our goal is to maintain commitment to your health and well-being by sourcing the highest quality ingredients in addition to assuring you wholesome goodness. We recognize that trustworthiness is earned through transparency and reliability, and we strive to create lasting relationships with our customers by ensuring that our products meet the highest expectations for safety and efficacy.
                </p>
                {/* Regular second paragraph */}
                <p
                  style={{
                    fontSize: "13px",
                    lineHeight: "1.3rem",
                    marginBottom: "20px", // Add space after the second paragraph
                    fontWeight: 200,
                  }}
                >
                As a responsible community partner, we remain committed to practicing safety and helping others in need whether on or off the road. This has made us into the leader that we are trusted to be in health and wellness. If you have any questions or concerns please contact our Customer Care Team.
                </p>
                {/* Button */}
                <div style={{ marginBottom: 20, padding: "1px 3px 3px 3px" }}>
                  <Link to="/about">
                  <Button
                    className="read-more-btn"
                    style={{
                      fontWeight: 500,
                      fontSize: "25px", // Smaller text for button
                      marginTop: "20px", // Margin at the top for spacing
                      borderRadius: "0px",
                      marginBottom: 30,
                      padding: "8px 18px",
                    }}
                  >
                    READ MORE
                  </Button>
                  </Link>
                </div>
              </div>
            </Col>

            {/* Image Column */}
            <Col md={4} className="commitment-image p-0">
              <img
                src={commitm}
                alt="Pills"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/*second section */}
      <section className="keys-to-success">
        <Container fluid className="p-0">
          <Row className="g-0">
            {/* Text Column */}
            <Col
              md={6}
              className="commitment-text p-0 d-flex"
              style={{
                backgroundColor: "#28282B",
                color: "#fff",
                justifyContent: "flex-start", // Aligns items to the left initially
              }}
            >
              <div
                style={{
                  marginLeft: "44.5%", // Push the content to the right, leaving 5/8 empty
                  marginRight: "3.1%", // Push the content to the right, leaving 5/8 empty
                  paddingTop: "40px",
                  justifyContent: "flex-end",
                }}
                className="commitment-text-div"
              >
                {/* Heading */}
                <h2
                  style={{
                    fontWeight: "bold",
                    fontSize: "42px",
                    color: "#fff",
                    marginBottom: "20px",
                    textAlign: "left", // Left align the text
                  }}
                >
                The Key to Our Success
                </h2>
                {/* Bold first paragraph */}
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: "17px",
                    marginBottom: "0px", // Add space after the first paragraph
                    padding: "0 0 27px 0",
                    lineHeight: "25px",
                  }}
                >
                At Duovit, we understand that what has brought us success is our attention to quality and a focus on customer satisfaction built around new innovation. Through nurturing great partnerships, an on-the-ball continuous process improvement and prioritizing the needs of our clients;we create lasting value and drive positive outcomes for everyone involved.
                </p>
                {/* Regular second paragraph */}
                <p
                  style={{
                    fontSize: "13px",
                    lineHeight: "1.3rem",
                    marginBottom: "20px", // Add space after the second paragraph
                    fontWeight: 200,
                  }}
                >
                Duovit manages the products that our clients need to be transported from one place to another. From or to the nearest point of your destination.We manage the transportation process, we control the trucking process, freight documentation, and handle routine tasks using the newest information technologies.
                </p>
                {/* Button */}
                <div style={{ marginBottom: 20, padding: "1px 3px 3px 3px" }}>
                <Link to="/about">
                  <Button
                    className="read-more-btn2"
                    style={{
                      fontWeight: 500,
                      fontSize: "25px", // Smaller text for button
                      marginTop: "20px", // Margin at the top for spacing
                      borderRadius: "0px",
                      padding: "8px 18px",
                    }}
                  >
                    READ MORE
                  </Button>
                  </Link>
                </div>
              </div>
            </Col>

            {/* Image Column */}
            <Col
              md={6}
              className="commitment-image p-0"
              style={{ backgroundColor: "#3A3A3C" }}
            >
              <div
                style={{
                  marginLeft: "3%",
                  marginRight: "12.5%",
                  paddingTop: "40px",
                  justifyContent: "flex-end",
                }}
              >
                {/* Heading */}
                <h2
                  style={{
                    fontWeight: "bold",
                    fontSize: "42px",
                    color: "#fff",
                    marginBottom: "20px",
                    textAlign: "left",
                  }}
                >
                  COMPREHENSIVE <br /> SERVICES
                </h2>

                {/* List of services */}
                <ul style={{ listStyleType: "none", padding: "0 20px 20px 0" }}>
                  <li
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: 500,
                      marginBottom: "10px",
                      borderBottom: "1px solid #58585A",
                      paddingBottom: "10px",
                      textIndent: "15px",
                    }}
                  >
                  Worldwide Transport
                  </li>
                  <li
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: 500,
                      marginBottom: "10px",
                      borderBottom: "1px solid #58585A",
                      paddingBottom: "10px",
                      textIndent: "15px",
                    }}
                  >
                  Warehousing
                  </li>
                  <li
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: 500,
                      marginBottom: "10px",
                      borderBottom: "1px solid #58585A",
                      paddingBottom: "10px",
                      textIndent: "15px",
                    }}
                  >
                  Sales
                  </li>
                  <li
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: 500,
                      marginBottom: "10px",
                      borderBottom: "1px solid #58585A",
                      paddingBottom: "10px",
                      textIndent: "15px",
                    }}
                  >
                  Marketing
                  </li>
                  <li
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: 500,
                      marginBottom: "10px",
                      borderBottom: "1px solid #58585A",
                      paddingBottom: "10px",
                      textIndent: "15px",
                    }}
                  >
                  Logistics
                  </li>
                  <li
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: 500,
                      marginBottom: "10px",
                      borderBottom: "1px solid #58585A",
                      paddingBottom: "10px",
                      textIndent: "15px",
                    }}
                  >
                  Research & Development
                  </li>
                  <li
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: 500,
                      marginBottom: "10px",
                      paddingBottom: "10px",
                      textIndent: "15px",
                    }}
                  >
                  Regulatory
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* third section */}
      <section className="parallax-section">
        <div className="container-fast-safe">
          <div className="row justify-content-center justify-content-lg-end">
            <div className="col-lg-7 col-md-9 col-sm-11 text-white content-area">
              <h2 className="fast-safe-heading">MAKING TRANSPORTATION FAST AND SAFE</h2>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "20px", // Add space after the second paragraph
                  fontWeight: 700,
                  color: "#fff",
                }}
                className="fast-safe-text"
              >
              We have stood by the same operating principles since day one.. That includes both our will to serve clients within any corner of the world just as much as to offer all types of cargo shipment.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* fourth section */}
      <section className="info-section py-5">
        <div className="container">
          <div className="row justify-content-center" style={{ display: "flex", alignItems: "stretch" }}>
            <div className="col-lg-3 col-md-6 d-flex flex-column">
              <h2 className="font-weight-bold text-left info-section-heading">Who We Are</h2>
              <p className="highlight-text text-left flex-grow-2">
              Duovit is a team that loves health and life, based on natural nutrition supplements we would like to serve through food, beverages or in the form of vitamins. </p>
              <p className="text-left under-highlight-text flex-grow-1">
              Wellness Everywhere.. We want to offer the best, most comforting and safest products that give our customers a healthier life. We pride ourselves on being your trusted partner working towards your best health — with the added touch of sustainability and community.
            </p>
              <div className="text-left mt-auto">
                <a href="/about" className="btn btn-danger read-more-btn3">
                  READ MORE
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 d-flex flex-column">
              <h2 className="font-weight-bold text-left info-section-heading">Why Us</h2>
              <p className="highlight-text text-left flex-grow-2">
              By choosing Duovit you choose quality, reliability and a promise of good health.</p>
              <p className="text-left under-highlight-text flex-grow-1">
              We order and make sure, for you to get what is best. We care about customer satisfaction and we have a committed team who are here to provide tailored support that is suited to your health needs. We also have a unique commitment to sustainability and civic activism—endeavors that are forever a part of everything we do. When you buy Duovit, it is not a choice of buying products — but building up a relationship with someone who puts their heart into supplying solutions that are good for your health and environment.

            </p>
              <div className="text-left mt-auto">
                <a href="/about" className="btn btn-danger read-more-btn3">
                  READ MORE
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 d-flex flex-column">
              <h2 className="font-weight-bold text-left info-section-heading">What We Do</h2>
              <p className="highlight-text text-left flex-grow-2">
              At Duovit we are specialized in delivering high-end food, beverages and vitamins to support you along your journey.</p>
              <p className="text-left under-highlight-text flex-grow-1">
              Made from only the best ingredients, Our extensive product range is crafted with the finest ingredients to ensure optimal nutrition and satisfaction.  We monitor the entire supply chain from sourcing and quality control to on-time delivery, so you can be sure your products arrive safely in time. We believe in serving our customers by offering dependable healthy lifestyle solutions.

            </p>
              <div className="text-left mt-auto">
                <a href="/about" className="btn btn-danger read-more-btn3">
                  READ MORE
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* fifth section */}
      <section className="last-section">
        <Container fluid className="p-0">
          <Row className="g-0">
            {/* Text Column */}
            <Col
              md={6}
              className="last-section-column p-0 d-flex flex-column"
              style={{
                backgroundColor: "#3A3A3C",
                justifyContent: "center", // Center the content vertically
                padding: "50px",
                alignItems: "center", // Center items horizontally
              }}
            >
              {/* Service 1 */}
              <div className="service-row d-flex align-items-center mb-4" style={{ width: "100%" }}>
                <div className="icon-box" style={{ flexShrink: 0 }}>
                  <FaBoxOpen className="icon" />
                </div>
                <div className="service-text" style={{ marginLeft: "10px", flex: 1 }}>
                  <h4 className="text-white mb-0" style={{ textTransform: "uppercase", whiteSpace: "normal" }}>
                  Efficient Distribution
                  </h4>
                </div>
              </div>

              {/* Service 2 */}
              <div className="service-row d-flex align-items-center mb-4" style={{ width: "100%" }}>
                <div className="icon-box" style={{ flexShrink: 0 }}>
                  <FaTruck className="icon" />
                </div>
                <div className="service-text" style={{ marginLeft: "10px", flex: 1 }}>
                  <h4 className="text-white mb-0" style={{ textTransform: "uppercase", whiteSpace: "normal" }}>
                  Reliable Transportation
                  </h4>
                </div>
              </div>

              {/* Service 3 */}
              <div className="service-row d-flex align-items-center mb-4" style={{ width: "100%" }}>
                <div className="icon-box" style={{ flexShrink: 0 }}>
                  <IoChatbubbles className="icon" />
                </div>
                <div className="service-text" style={{ marginLeft: "10px", flex: 1 }}>
                  <h4 className="text-white mb-0" style={{ textTransform: "uppercase", whiteSpace: "normal" }}>
                 Logistics Management
                  </h4>
                </div>
              </div>
            </Col>

            {/* Image Column */}
            <Col
              md={6}
              className="commitment-image p-0 d-flex"
            >
              <img
                src={loadingup2}
                alt="Pills"
                style={{
                  width: "100%",
                  height: "auto", // Maintain the aspect ratio of the image
                  maxHeight: "100vh", // Ensure the image doesn’t exceed viewport height
                  objectFit: "cover",
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
}

export default Landing;
