import React from "react";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import { Card, Form, Container, Row, Col, Button, Toast } from "react-bootstrap";
import "./ContactUs.css";

function ContactUs() {
  const [showToast, setShowToast] = React.useState(false);

  const handleSendClick = () => {
    // Show the toast message
    setShowToast(true);

    // Optionally, you can add logic here to handle form submission
  };

  return (
    <div className="App">
      <NavBar />
      <section className="contact" style={{ background: "#EBEBEB" }}>
        <Container fluid className="p-0">
          <Row className="g-0">
            {/* First Column - Align content to the right */}
            <Col
              md={6}
              sm={6}
              className="d-flex justify-content-end align-items-center p-3"
            >
              <div className="contact-info text-right">
                <h1 className="contact-heading">Contact us</h1>
                <p className="contact-address">
                  Duo Vit
                </p>
                <p className="contact-phone">Phone: +962790300178</p>
                <p className="contact-email">Email: official@duovit.info</p>
              </div>
            </Col>

            {/* Second Column - Align content to the left */}
            <Col
              md={6}
              className="d-flex justify-content-start align-items-center p-3"
            >
              <Card className="contact-card">
                <Card.Body>
                  <Form className="contact-form">
                    {/* Home input */}
                    <Form.Group controlId="firstName">
                      <Form.Control type="text" placeholder="First Name*" />
                    </Form.Group>

                    <Form.Group controlId="lastName">
                      <Form.Control type="text" placeholder="Last Name*" />
                    </Form.Group>

                    {/* Email input */}
                    <Form.Group controlId="formEmail" className="mt-1">
                      <Form.Control type="email" placeholder="E-mail*" />
                    </Form.Group>

                    <Form.Group controlId="phoneNumber" className="mt-1">
                      <Form.Control type="text" placeholder="Phone Number*" />
                    </Form.Group>

                    {/* Date input */}
                    <Form.Group controlId="formDate" className="mt-1">
                      <Form.Control type="date" placeholder="mm/dd/yyyy" />
                    </Form.Group>

                    {/* Message input */}
                    <Form.Group controlId="formMessage" className="mt-1">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Your Message"
                      />
                    </Form.Group>

                    {/* Submit button */}
                    <div className="d-flex justify-content-center mt-4">
                      <Button
                        variant="light"
                        className="contact-submit-button"
                        onClick={handleSendClick}
                      >
                        Send
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />

      {/* Toast message */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: 'fixed',
          top: '20%',
          right: '1%',
          zIndex: 1050,
          backgroundColor: '#B22222',
          border: '1px solid white',
          borderRadius: '1.25rem',
          color: 'white',
        }}
      >

        <Toast.Body>
          Thank you for reaching out! We will get back to you shortly.
        </Toast.Body>
      </Toast>
    </div>
  );
}

export default ContactUs;
