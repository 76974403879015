import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Products from "./Pages/Products/Products";
import Landing from "./Pages/Landing/Landing";
import Partners from "./Pages/Partners/Partners";
import ContactUs from "./Pages/ContactUs/ContactUs";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/products" element={<Products />} />
      <Route path="/partners" element={<Partners />} />
      <Route path="/contact" element={<ContactUs />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
