import React, { useState } from "react";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import { GearFill } from "react-bootstrap-icons"; // Importing the settings icon
import "./AboutUs.css"; // Import the CSS file
import { ImFileMusic, ImGift } from "react-icons/im";
import { Container, Row, Col } from "react-bootstrap";
import { CiPillsBottle1 } from "react-icons/ci";
import { FaPlus, FaMinus } from "react-icons/fa"; // Import plus and minus icons
import PillsImg23 from "../../Components/Images/PillsImg23.jpg";
import { RiCustomerService2Fill } from "react-icons/ri";
import { RiShakeHandsLine } from "react-icons/ri";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { FaHandshake } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa6";
function AboutUs() {
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleExpand = (section) => {
    setExpandedSection((prevSection) => (prevSection === section ? null : section));
  };

  const services1 = [
    {
      icon: <RiCustomerService2Fill className="icon-img" />,
      title: "CUSTOMER SERVICE",
      description: "We employ the right people and train them to deliver exceptional service.",
    },
    {
      icon: <AiFillSafetyCertificate className="icon-img" />,
      title: "SAFETY & RELIABILITY",
      description: "99.5% of our shipments are completed without any problems.",
    },
    {
      icon: <FaRegHandshake className="icon-img" />,
      title: "TEAMWORK",
      description: "We view our customers as partners and focus on building long-term relationships.",
    },
  ];

  const services2 = [
    {
      title: "Expand Product Portfolio",
      description: "Continuously enhance our offerings by sourcing and introducing new branded vitamins, minerals, and wellness products that meet the evolving needs of healthcare providers and consumers in both private and public sectors." },
    {
      title: "Strengthen Relationships",
      description: "Build and maintain long-term partnerships with pharmacies, hospitals, and wellness centers through exceptional service, regular feedback, and tailored solutions, ensuring customer satisfaction and loyalty." },
    {
      title: "Enhance Market Presence",
      description: "Increase our market share in the Middle East by implementing targeted marketing strategies and establishing strategic collaborations, positioning Duovit as the go-to distributor for health and wellness products." },
  ];

  return (
    <div>
      <>
        <NavBar />
        <section className="last-section">
          <Container fluid className="p-0">
            <Row className="g-0">
              {/* First Column */}
              <Col
                md={6}
                className="commitment-image p-0 d-flex flex-column justify-content-center align-items-start"
                style={{
                  backgroundColor: "#28282B",
                  justifyContent: "center",
                  padding: "50px",
                  alignItems: "center",
                }}
              >
                  <Col md={{ span: 8, offset: 4 }} style={{ paddingTop: "6rem" }} className="values-section">
                    <h1 style={{ color: "white", marginBottom: "30px", fontSize: "50px", fontWeight: "bold" }}>OUR VALUES</h1>
                    {services1.map((service, index) => (
                      <div
                        className="service-row4 d-flex align-items-center justify-content-start mb-4"
                        key={index}
                      >
                        <div className="icon-box" style={{ minWidth: "40px", height: "100px" }}>
                          {service.icon}
                        </div>
                        <div className="service-text" style={{ flex: 1, textAlign: "left" }}>
                          <h4 className="text-white" style={{ textAlign: "left" }}>
                            {service.title}
                          </h4>
                          <p style={{ textAlign: "left" }}>{service.description}</p>
                        </div>
                      </div>
                    ))}
                  </Col>
              </Col>

              {/* Second Column */}
              <Col
                md={6}
                className="commitment-image p-0 d-flex flex-column justify-content-center align-items-center"
                style={{ backgroundColor: "#3A3A3C", padding: "50px", position: "relative" }}
              >
                {/* First Row */}
                <Row className="mb-4" style={{ marginTop: "-4rem", position: "relative" }}>
                  <div className="prices-section" style={{ paddingLeft: "1rem" }}>
                    <h1 className="text-white strategy-header" style={{ fontSize: "3rem", fontWeight: "bold" }}>
                      GROWTH STRATEGY
                    </h1>
                  </div>
                </Row>

                {/* Second Row */}
                <Row className="mb-4" style={{ marginTop: "3rem", position: "relative", width: "100%" }}>
                  <div style={{ position: "relative", width: "100%" }}>
                    {services2.map((service, index) => (
                      <div key={index} className="mb-3">
                        <div
                          className="service-row d-flex align-items-center justify-content-between"
                          style={{
                            width: "80%",
                            cursor: "pointer",
                            borderBottom: "1px solid white",
                            paddingLeft: "10px",
                          }}
                          onClick={() => toggleExpand(index)}
                        >
                          <h4
                            className="text-white service-title-h4"
                            style={{
                              textAlign: "left",
                              color: expandedSection === index ? "red" : "white",
                              flex: 1,
                              marginBottom: "0.5rem",
                            }}
                          >
                            {service.title}
                          </h4>
                          <div style={{ color: "white" }} className="service-title-h4">
                            {expandedSection === index ? <FaMinus /> : <FaPlus />}
                          </div>
                        </div>

                        {/* Description Section */}
                        <div style={{ position: "relative", overflow: "hidden" }}>
                          {expandedSection === index && (
                            <p
                              style={{
                                textAlign: "left",
                                color: "white",
                                marginTop: "0.5rem",
                                fontSize: "1rem",
                                width: "80%",
                                transition: "max-height 0.3s ease",
                                maxHeight: expandedSection === index ? "500px" : "0px",
                                overflow: "hidden",
                              }}
                              className="service-description"
                            >
                              {service.description}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </Row>
              </Col>
            
            
            </Row>
          </Container>  
        </section>
        <section className="info-section py-5">
        <div className="container">
          <div className="row justify-content-center" style={{ display: "flex", alignItems: "stretch" }}>
          <div className="col-lg-3 col-md-6 d-flex flex-column">
          <h2 className="font-weight-bold text-left info-section-heading">LEADERSHIP</h2>
          <p className="highlight-text text-left flex-grow-2">
          At Duovit, we proudly lead in distributing branded vitamins, minerals, pharmaceuticals, and OTC products, with a strong reputation for delivering high-quality solutions to the healthcare industry.</p>
          <p className="text-left under-highlight-text flex-grow-1">
          Our leadership team focuses on understanding the needs of healthcare providers. By offering trusted brands, we ensure clients have access to top products. 
          Our success lies in meeting demands and building lasting relationships, enhancing health and wellness in our communities.
          </p>
          <div className="text-left mt-auto">
            <a href="/about" className="btn btn-danger read-more-btn3">
              READ MORE
            </a>
          </div>
        </div>
            <div className="col-lg-3 col-md-6 d-flex flex-column">
              <h2 className="font-weight-bold text-left info-section-heading">HISTROY</h2>
              <p className="highlight-text text-left flex-grow-2">
              Duovit is a top importer and distributor, supplying pharmacies, hospitals, and wellness centers with a wide range of branded vitamins, minerals, para-pharmaceuticals, and OTC products for both private and public sectors.</p>
              <p className="text-left under-highlight-text flex-grow-1">
              At duovit, we understand the needs of pharmacists, doctors, and healthcare providers. Our diverse portfolio, efficient service, and commitment to product value are paramount to our customers' satisfaction.
              <br />
              We believe our success stems from our ability to meet market demands while fostering long-term, mutually beneficial relationships with our clients.

            </p>
              <div className="text-left mt-auto">
                <a href="/about" className="btn btn-danger read-more-btn3">
                  READ MORE
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex flex-column">
            <h2 className="font-weight-bold text-left info-section-heading">CAREERS</h2>
            <p className="highlight-text text-left flex-grow-2">
            Join our esteemed wholesale distribution company based in Jordan specializing in health and wellness products.</p>
            <p className="text-left under-highlight-text flex-grow-1">
            Join our esteemed wholesale distribution company, proudly based in Jordan, which specializes in offering a comprehensive range of health and wellness products. We are dedicated to serving the 
            needs, ensuring quality solutions for pharmacies, hospitals, and wellness centers across the region</p>
            <div className="text-left mt-auto">
              <a href="/about" className="btn btn-danger read-more-btn3">
                READ MORE
              </a>
            </div>
          </div>
           
          </div>
        </div>
      </section>
        <Footer />
      </>
    </div>
  );
}

export default AboutUs;
